import Empresa1 from "../../assets/vsurbana.webp";
import Empresa2 from "../../assets/sil.webp";
import Empresa3 from "../../assets/DANIELA.webp";
import Empresa4 from "../../assets/florista.webp";
import Empresa5 from "../../assets/artesis.webp";
import Empresa6 from "../../assets/PERUVIAN-SCIENCE.webp";

export const Data = [
  {
    id: 1,
    image: Empresa1 ,
  },
  {
    id:2,
    image: Empresa2 ,
  },
  {
    id:3,
    image: Empresa3 ,
  },
  {
    id:4,
    image: Empresa4 ,
  },
  {
    id:5,
    image: Empresa5 ,
  },
  {
    id:6,
    image: Empresa6 ,
  },
];
